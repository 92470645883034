/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343A50;
  --card-background-color: #804980;
  --countdown-background-color: #433765;
  --main-text-color: #F7F6F4;
  --title-text-color: #3CBA8B;
}

body {
  background-color: white;
  background-size: auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 75%;
  overflow-x: hidden;
  overflow-y: auto;

  font-size: 1.1em;
}


h2,
p {
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}

.burn-converter{
  font-size: 50px!important;
  font-weight: bold!important;
}
.baloo-font-family {
  font-family: "Baloo Thambi 2" !important;
}

.swole-img {
  width: 240px;
  height: 300px;
}

.gaps-100 {
  width: 100px;
}

.swole-small-img {
  width: 180px;
  height: 190px;
}

p {
  margin: 1px !important;
}

.guide-box {
  background-color: #151729;
  padding: 4px 8px;
  margin: 0px;
}

.arrow-img-container {
  /* height: 100%; */
  margin: 0;
  height: 200px;
}

@media screen and (max-width: 768px) {
  .how-to {
    flex-direction: column !important;
    align-items: center !important;
    font-size: 12px !important;
  }

  .arrow-img-container {
    /* height: 100%; */
    margin: 0;
    height: auto;
  }


  .arrow-img {
    transform: rotate(90deg);
    width: 50px;
    height: 100px;
  }

  .swole-small-img {
    width: 60px;
    height: 63px;
  }

  .swole-img {
    width: 80px;
    height: 100px;
  }


}

.logo-brand {
  display: flex;
  align-items: center;
}
.logo-brand-token {
  margin-right: 20px;
}

.token-name {
  font-weight: bold!important;
  font-size: 1.5rem!important;
}

.text-center {
  text-align: center;
}

.init-button {
  background: #E6BE4B!important;
  color: black!important;
  font-weight: bold!important;
  font-size: 1.5rem!important;
  border-radius: 10px!important;
}



@media screen and (max-width: 900px) {
  .logo-brand {
    display: none;
  }
}

.burn-box {
  position: relative;
}

.init-button-box {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

label.Mui-focused {
  color: 'white',
}

.customizedOutlinedInput {
  border: 1px solid white;
}

.customizedOutlinedInput input {
  color: white;
}

.customizedOutlinedInput p {
  color: white;
  font-family: "Mainfont";
}

.usd-text{
  color: #E6BE4B!important;
}