@font-face {
  font-family: 'Mainfont';
  src: url('./font/tommys.ttf');
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Mainfont', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::placeholder {
  color: white !important; /* Set the color of the placeholder text to white for all input elements */
}

